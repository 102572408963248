import React, { useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useStyletron } from 'styletron-react';

import { GET_OWNER_DETAILS } from '../../views/Home/graphql';

import OwnerAccountCardMenu from './OwnerAccountCardMenu';
import VisibleOn, { HiddenOn } from '../hoc/visible-on';
import Typography from '../ui/typography';
import Card from '../ui/card';
import Grid from '../ui/grid';
import Loading from '../ui/loading';
import Image from '../ui/image';
import ImagePlaceHolder from '../ui/imagePlaceHolder';

import Theme from '../../context/theme';

const OwnerAccountCard = ({ history, account, ...otherProps }) => {
  const client = useApolloClient();
  const { theme } = React.useContext(Theme);

  /** STATE */
  const [ownerDetails, setOwnerDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const [css] = useStyletron();
  const { customerNo, parkId, parkName, plotNo } = account;

  const isMobile = window.innerWidth < theme.breakpoints.width('md');

  const styles = useMemo(
    () => ({
      card: {
        position: 'relative',
        flexDirection: 'row',
        minHeight: '185px',
        paddingTop: `${theme.spacing(2)}px !important`,
        ...(otherProps.style || {}),
      },
      menu: {
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        ...(isMobile
          ? {
              position: 'absolute',
              top: `${theme.spacing(2)}px`,
              right: `${theme.spacing(2)}px`,
            }
          : {}),
      },
      noMargins: { margin: '0px !important' },
      image: {
        padding: `${theme.spacing(2)}px`,
        flexShrink: 0,
      },
      logo: { height: '150px' },
    }),
    [isMobile, otherProps.style, theme],
  );

  /** USE EFFECTS */
  React.useEffect(() => {
    let cancelled = false;
    const getInitialData = async () => {
      try {
        const { data } = await client.query({
          query: GET_OWNER_DETAILS,
          variables: { customerNo, parkId },
        });
        if (!cancelled) setOwnerDetails(data);
      } catch (err) {
        // continue regardless of error as only affects balance
      }

      if (!cancelled) setLoading(false);
    };

    getInitialData();

    return () => {
      cancelled = true;
    };
  }, [client, customerNo, parkId]);

  // const [getOwnerDetails, { loading, data: ownerDetails }] =
  //   useLazyQuery(GET_OWNER_DETAILS)

  // /** USE EFFECTS */
  // React.useEffect(() => {
  //   getOwnerDetails({ variables: { customerNo, parkId } })
  // }, [customerNo, getOwnerDetails, parkId])

  const { balance = null } = ownerDetails?.ownerDetails || {};
  const { park } = ownerDetails;
  const { path, alternateText } = park?.logo ?? {};

  const balanceValue = isNaN(balance) ? 0 : balance || 0;
  const textColor = balanceValue === 0 ? null : balanceValue < 0 ? 'red' : 'green';

  const balanceText = `${balanceValue < 0 ? 'You owe' : 'Your balance is'} ${Math.abs(
    balanceValue,
  ).toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
  })}`;

  return (
    <Card elevation={4} color="white" {...otherProps} className={css(styles.card)}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {park ? (
            <HiddenOn
              breakpoints={['xs', 'sm']}
              component={
                <div className={css(styles.image)}>
                  {path ? (
                    <Image
                      className={css(styles.logo)}
                      src={`//assets.parkholidays.com/assets/${path}`}
                      alt={alternateText}
                    />
                  ) : (
                    ImagePlaceHolder
                  )}
                </div>
              }
            />
          ) : null}

          <Grid alignItems="flex-start" className={css({ ...styles.noMargins, flex: 1 })}>
            <Grid item xs={10} md={6}>
              <Typography as="p">{customerNo}</Typography>

              <Typography as="p" color="corporate">
                Park: <Typography weight="bold">{parkName}</Typography>
                <br />
                Pitch: <Typography weight="bold">{plotNo}</Typography>
              </Typography>

              {/* <Typography className={css({ textTransform: 'uppercase' })}>
                Total Balance:
              </Typography> */}
              <Typography variant="h1" as="p" color={textColor} weight="semi-bold" noMargins>
                {balanceText}
              </Typography>
              <Typography style={{fontSize: "12px"}} as="span" color={textColor} weight="light" noMargins>
                *balance may include future charges
              </Typography>
            </Grid>

            <HiddenOn
              breakpoints={['xs', 'sm']}
              component={
                <Grid item md={6}>
                  <OwnerAccountCardMenu
                    customerNo={customerNo}
                    menuStyles={styles.menu}
                    optionalStyles={css({
                      paddingRight: `${theme.spacing(2)}px`,
                    })}
                  />
                </Grid>
              }
            />
          </Grid>

          <VisibleOn
            breakpoints={['xs', 'sm']}
            component={
              <OwnerAccountCardMenu
                customerNo={customerNo}
                menuStyles={styles.menu}
                optionalStyles={css({ paddingRight: `${theme.spacing(2)}px` })}
              />
            }
          />
        </>
      )}
    </Card>
  );
};

export default OwnerAccountCard;

OwnerAccountCard.propTypes = {
  account: PropTypes.shape({
    customerNo: PropTypes.string.isRequired,
    parkName: PropTypes.string.isRequired,
    plotNo: PropTypes.string.isRequired,
    parkId: PropTypes.number.isRequired,
  }).isRequired,
};
